<template>
  <div :id="id" class="badge align-middle align-text-top align-text-bottom clickable" :class="[variantStyle,pill,readOnlyClass]" @[badgeClickEvent]="badgeClick">
    <b-popover
      v-if="errorMessage"
      :target="`BADGE_ERROR_${id}`"
      placement="top"
      triggers="hover"
      :content="errorMessage">
    </b-popover>
    <b-popover
      v-if="enableTooltip"
      :target="id"
      placement="top"
      boundary="viewport"
      triggers="hover"
      :content="text">
    </b-popover>
    <font-awesome-icon :id="`BADGE_ERROR_${id}`" class="mr-1 error-align" v-if="errorMessage" style="color: red;font-size: 1.125em;" :icon="['fas', 'triangle-exclamation']"/>

    <div v-if="!edit" class="badge-text">{{text}}<span v-if="attribute !== null" class="text-attr">{{ attribute }}</span><font-awesome-icon v-if="icon !== null" class="ml-1 text-icon" :icon="['far',icon]"/></div>
    <div v-if="edit" class="autocomplete">
      <span class="hidden-edit-val">{{ editVal }}</span>
      <input ref="edit" list="listvalues" class="badge-edit" v-model="editVal" v-on:keyup.enter="onEnter" v-on:input="onInput" v-on:keydown="onKeydown" v-on:blur="onBlur" autofocus="true"/>
    </div>
    <span v-if="!readOnly" class="clickable badge-close" @[badgeRemoveEvent]="badgeRemove">
      <font-awesome-icon class="edit-icon-color" :icon="['far', 'xmark']"/>
    </span>
  </div>
</template>

<script>
export default {
  name: 'Badge',
  props: {
    "text": {
      type: String,
      required: true
    },
    "attribute": {
      type: String,
      required: false,
      default: null
    },
    "icon": {
      type: String,
      required: false,
      default: null
    },
    "variant": {
      type: String,
      default: null
    },
    "pillable": {
      type: Boolean,
      default: false
    },
    "errorMessage": {
      type: String,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    enableClickWhenReadOnly: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Boolean,
      default: false
    },
    listvalues: {
      type: Array,
      default: () => []
    },
    enableTooltip: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      id: Math.random().toString(36).substr(2, 9),
      editVal: null,
      enter: false, // track the enter key and if pressed don't handle blur
      currentFocus: -1
    }
  },
  created() {
    this.editVal = this.text;
  },
  mounted() {
    if (this.edit) {
      this.$refs.edit.focus();
    }
  },
  computed: {
    variantStyle:function(){
      return this.variant? `badge-${this.variant}`: '';
    },
    pill:function(){
      return (this.pillable == true) ? 'badge-pill' : '';
    },
    readOnlyClass: function() {
     return this.readOnly == true? 'read-only': '';
    }
    , badgeClickEvent() {
      return this.isTouchDevice()? 'touchend' : 'click';
    }
    , badgeRemoveEvent() {
      return this.isTouchDevice()? 'touchend' : 'click';
    }
  },
  watch: {
    text(newValue) {
      this.editVal = newValue;
    }
  },
  methods: {
    badgeClick(event) {
      event.stopPropagation();
      if (this.readOnly != true || this.enableClickWhenReadOnly) {
        this.$emit('badgeClick', this.$el);
      }
    },
    badgeRemove(event) {
      event.stopPropagation();
      this.$emit('badgeRemove', this.$el);
    },
    onEnter() {
      if (this.currentFocus === -1) {
        this.enter = true;
        this.$emit('commit', this.editVal);
        this.closeAllLists(this.$refs.edit);
      }
    },
    onBlur(/**e */) {
      if (!this.enter) {
        this.onEnter();
      }
    },
    onInput(e) {
      const self = this;
      const arr = this.listvalues;
      var a, b, i, val = this.editVal;
      var count = 0;
      /*close any already open lists of autocompleted values*/
      self.closeAllLists();
      if (!val) { return false;}
      self.currentFocus = -1;
      /*create a DIV element that will contain the items (values):*/
      a = document.createElement("DIV");
      a.setAttribute("id", this.id + "autocomplete-list");
      a.setAttribute("class", "autocomplete-items");
      /*append the DIV element as a child of the autocomplete container:*/
      e.target.parentNode.appendChild(a);
      /*for each item in the array...*/
      for (i = 0; i < arr.length; i++) {
        /*check if the item starts with the same letters as the text field value:*/
        if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
          count++;
          /*create a DIV element for each matching element:*/
          b = document.createElement("DIV");
          /*make the matching letters bold:*/
          b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
          b.innerHTML += arr[i].substr(val.length);
          /*insert a input field that will hold the current array item's value:*/
          b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
          /*execute a function when someone clicks on the item value (DIV element):*/
          b.addEventListener("click", function(e) {
              /*insert the value for the autocomplete text field:*/
              self.editVal = e.target.getElementsByTagName("input")[0].value;
              /*close the list of autocompleted values,
              (or any other open lists of autocompleted values:*/
              self.closeAllLists();
          });
          b.addEventListener("mousedown", function(e) {
              /*insert the value for the autocomplete text field:*/
              self.editVal = e.target.tagName === 'STRONG' ? 
                e.target.parentElement.getElementsByTagName("input")[0].value : 
                e.target.getElementsByTagName("input")[0].value;
              /*close the list of autocompleted values,
              (or any other open lists of autocompleted values:*/
              self.closeAllLists();
          });
          a.appendChild(b);
        }
      }
      
      if (count > 0) {
        a.classList.add('d-block');
      }
    },
    onKeydown(e) {
      const self = this;
      var x = null;
      var listElem = e.target.parentElement.children[2]; // get the list
      if (listElem) x = listElem.getElementsByTagName("div");
      if (e.keyCode == 40) {
        /*If the arrow DOWN key is pressed,
        increase the currentFocus variable:*/
        self.currentFocus++;
        /*and and make the current item more visible:*/
        self.addActive(x, listElem);
      } else if (e.keyCode == 38) { //up
        /*If the arrow UP key is pressed,
        decrease the currentFocus variable:*/
        self.currentFocus--;
        /*and and make the current item more visible:*/
        self.addActive(x, listElem);
      } else if (e.keyCode == 13) {
        /*If the ENTER key is pressed, prevent the form from being submitted,*/
        e.preventDefault();
        if (self.currentFocus > -1) {
          /*and simulate a click on the "active" item:*/
          if (x) x[self.currentFocus].click();
        }
      }
    },
    addActive(x, listElem) {
      /*a function to classify an item as "active":*/
      if (!x) return false;
      /*start by removing the "active" class on all items:*/
      this.removeActive(x);
      if (this.currentFocus >= x.length) this.currentFocus = 0;
      if (this.currentFocus < 0) this.currentFocus = (x.length - 1);
      /*add class "autocomplete-active":*/
      x[this.currentFocus].classList.add("autocomplete-active");
      
      listElem.scrollTop = x[this.currentFocus].offsetTop;
    },
    removeActive(x) {
      /*a function to remove the "active" class from all autocomplete items:*/
      for (var i = 0; i < x.length; i++) {
        x[i].classList.remove("autocomplete-active");
      }
    },
    closeAllLists(elmnt) {
      this.currentFocus = -1;
      /*close all autocomplete lists in the document,
      except the one passed as an argument:*/
      var x = document.getElementsByClassName("autocomplete-items");
      for (var i = 0; i < x.length; i++) {
        if (elmnt != x[i] && elmnt != this.$refs.edit) {
          x[i].parentNode.removeChild(x[i]);
        }
      }
    }
    , isTouchDevice() {
      const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
      const mq = function (query) {
          return window.matchMedia(query).matches
      }
      if ('ontouchstart' in window) {
          return true
      }
      const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('')
      return mq(query)
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/style.scss";

.badge{
  position: relative;
  margin-right:10px;
  padding: 4px 26px 4px 10px;
  margin-bottom: 5px;
  line-height: 1;
  white-space: nowrap;
  max-width: 100%;
  font-weight: normal;
  height: 22px;
}
.badge-text {
  text-overflow: ellipsis;    
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  max-width: 400px;
  padding-bottom: 1px;
}
.badge-close {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 22px;
  text-align: center;
  line-height: 22px;
  border-radius: 0.25rem;
  font-size: 0.7rem;
  font-weight: normal;
}

.badge.badge-light {
  color: black;
}


.badge-pill .badge-close {
  border-radius: 10rem;
  right: 2px;
}

.badge .badge-close:hover {
  color: var(--white);
}

.badge-primary {
  .badge-close {
    color: darken($primary, 25%);
  }
  .badge-close:hover {
    background-color: darken($primary, 10%);
  }
}
.badge-secondary{
  .badge-close {
    color: darken($secondary, 25%);
  }
  .badge-close:hover {
    background-color: darken($secondary, 10%);
  }
}
.badge-success{
  .badge-close {
    color: darken($success, 25%);
  }
  .badge-close:hover {
    background-color: darken($success, 10%);
  }
}
.badge-info{
  .badge-close {
    color: darken($info, 25%);
  }
  .badge-close:hover {
    background-color: darken($info, 10%);
  }
}
.badge-warning{
  .badge-close {
    color: darken($warning, 25%);
  }
  .badge-close:hover {
    background-color: darken($warning, 10%);
  }
}
.badge-danger{
  .badge-close {
    color: darken($danger, 25%);
  }
  .badge-close:hover {
    background-color: darken($danger, 10%);
  }
}
.badge-light{
  .badge-close {
    color: darken($light, 25%);
  }
  .badge-close:hover {
    background-color: darken($light, 10%);
  }
}
/* White variant is used in dataview modals and not part of the usual
   badgegroups, so they are a little different.
*/
.badge-white{
  color: var(--form-control);
  background-color: var(--form-control-bg);
  font-size: 14px;
  border: 1px solid var(--form-control-border);
  padding: 10px 26px 5px 10px;
  height: unset;
  
  .badge-close {
    color: darken($light, 25%);
    padding-top: 6px;
  }
  .badge-close:hover {
    background-color: var(--badge-close-hover);
  }
  .badge {
    padding: 10px 26px 10px 10px;
  }
}
.badge-dark{
  .badge-close {
    color: lighten($dark, 25%);
  }
  .badge-close:hover {
    background-color: lighten($dark, 10%);
  }
}

.clickable{
  cursor:pointer;
}

.text-attr {
  font-size: 0.8em;
  bottom: 1px;
  position: relative;
}

.text-icon {
  top: 2px;
  position: relative;
}
.badge.read-only {
  padding-right: 10px;
  cursor: default;
  .badge-close {
    display: none;
  }
}

.badge-edit {
  color: white;
  font-size: 12px;
  height: auto;
  background: transparent;
  border: none;
  padding: 0;
  line-height: 12px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  outline: none !important;
}

.hidden-edit-val {
  padding-right: 10px;
  visibility: hidden;
  white-space: pre;
}

.badge-edit:focus {
  background-color: transparent;
}

.badge-edit:focus-visible,
.badge-edit:focus,
.badge-edit:active {
  outline: none !important;
}

/*the container must be positioned relative:*/
.autocomplete {
  position: relative;
  display: inline-block;
  max-width: 400px;
}

.autocomplete-items {
  display: none;
  position: absolute;
  border-bottom: none;
  border-radius: 3px;
  border-top: none;
  border: 1px solid var(--border-medium);
  background-color: var(--form-control-bg);
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
  overflow-y: auto;
  max-height: 300px;
  width: 100px;
}

.autocomplete-items div {
  padding: 10px;
  text-align: left;
  cursor: pointer;
  border-radius: 3px;
  color: var(--form-control);
  background-color: var(--form-control-bg); 
}

.error-align {
  vertical-align: baseline;
}

/*when hovering an item:
.autocomplete-items div:hover {
  background-color: var(--projectal-orange); 
}*/

/*when navigating through the items using the arrow keys:*/
.autocomplete-active {
  background-color: var(--projectal-orange) !important; 
  color: #ffffff; 
}
</style>