//Generic comparator
export function getComparator(prop) {
  if (prop == null) {
    return null;
  }
  return (valueA, valueB, nodeA, nodeB/**, isDescending */) => {
    const valA = nodeA?.data != null? nodeA.data[prop] : null;
    const valB = nodeB?.data != null? nodeB.data[prop] : null;
    if (valA == valB) return 0;
    return valA > valB ? 1 : -1;
  }
}

/**
 * prop: list property name in the row data
 * objProj: object propery name in each object in the list
 */
export function getObjectListComparator(prop, objProp) {
  if (prop == null || objProp == null) {
    return null;
  }
  return (valueA, valueB, nodeA, nodeB/**, isDescending */) => {
    const valA = nodeA?.data != null && Array.isArray(nodeA.data[prop])? nodeA.data[prop].map(i => i[objProp]).join('') : null;
    const valB = nodeB?.data != null && Array.isArray(nodeB.data[prop])? nodeB.data[prop].map(i => i[objProp]).join('') : null;
    if (valA == valB) return 0;
    return valA > valB ? 1 : -1;
  }
}